import React, { useState } from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import HeaderMobile from "../components/header/headerMobile"
import SlideSwiper from "../components/sliderSwiper/sliderSwiper";
import Stand from "../components/stand/stand"
import StandMobile from "../components/stand/standMobile"
import PopUpMobile from "../components/stand/popUpMobile"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

const StandPage = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  console.log(data.standJson)
  return (
    <>
      <SEO title="Stand" image={data.standJson.intro.background.childImageSharp.fluid.src} />
      <Layout >
        {useBreakpoint().mobile ? (
          <>
            <HeaderMobile data={data.globalJson.header} />

            <SlideSwiper data={[data.standJson.introMobile]} oneSlideBorder={true} mobile top />

            <StandMobile cars={data.allWpSimpleProduct.edges} btn={data.standJson.btnStand} />

            <PopUpMobile form={data.contactosJson.standForm} setIsOpen={setIsOpen} isOpen={isOpen} btnForm={data.standJson.btnForm} />

            <FooterMobile data={data.globalJson.footer} floatButtons={data.globalJson.floatButtons} setIsOpen={setIsOpen} isOpen={isOpen} stand={true} />
          </>
        ) : (
          <>
            <Header data={data.globalJson.header} />

            <SlideSwiper data={[data.standJson.intro]} oneSlideBorder={true} top />

            <Stand cars={data.allWpSimpleProduct.edges} btn={data.standJson.btnStand} />

            <Footer data={data.globalJson.footer} floatButtons={data.globalJson.floatButtons} setIsOpen={setIsOpen} isOpen={isOpen} stand={true} />
          </>
        )}
      </Layout>
    </>
  )
}
export default StandPage

export const Json = graphql`
  query standQuery {
    standJson {
      intro {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
      }
      introMobile {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
      }
      btnStand{
        btnText
        btnLink
        btnClass
      }
      btnForm{
        btnText
        btnLink
        btnClass
      }
    }
    contactosJson {
      standForm {
        title
        name
        phone
        email
      }
    }
    globalJson {
      header {
        links {
          link
          linkTxt
        }
        sidemenu {
          links {
            link
            linkTxt
            extraLinks {
              link
              linkTxt
            }
          }
          madeBy
        }
      }
      floatButtons{
        btn
        btnLink
      }
      footer {
        linksLeft {
          link
          linkTxt
        }
        linksRight {
          link
          linkTxt
        }
        image {
          childImageSharp {
            fluid(maxWidth: 198, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allWpSimpleProduct {
      edges {
        node {
          name
          image {
            sourceUrl
            title
          }
          stockQuantity
          price
          slug
          content
          shortDescription
          productCategories {
            nodes {
              parentDatabaseId
              databaseId
              name
              image {
                srcSet
              }
              description
            }
          }
          galleryImages {
            nodes {
              title
              sourceUrl
            }
          }
          paMesAnos {
            nodes {
              name
            }
          }
          paKms {
            nodes {
              name
            }
          }
          paModelos {
            nodes {
              name
            }
          }
          paMarcas {
            nodes {
              name
            }
          }
          paCombustivels {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`
